import { type ReactNode, type MouseEvent, useRef } from 'react';
import { ButtonSpinner, IconLeft, IconRight, Label, StyledButton } from './style';

export type ButtonProps = {
  children?: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text-primary' | 'text-secondary';
  severity?: 'danger' | 'warning' | 'info' | 'success';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  icon?: ReactNode;
  iconPlacement?: 'before' | 'after';
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  id?: string;
  testId?: string;
  preventDoubleClick?: boolean;
};

export const Button = ({
  children,
  variant,
  severity,
  icon,
  iconPlacement = 'before',
  fullWidth,
  disabled,
  isLoading,
  id,
  preventDoubleClick,
  testId,
  onClick,
  ...rest
}: ButtonProps) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  const onBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (btnRef.current && preventDoubleClick) {
      btnRef.current.setAttribute('disabled', 'disabled');
      setTimeout(() => {
        if (btnRef.current) {
          if (!disabled) {
            btnRef.current.removeAttribute('disabled');
          } else {
            btnRef.current.setAttribute('disabled', 'disabled');
          }
        }
      }, 100);
    }
    !isLoading && onClick && onClick(e);
  };

  return (
    <StyledButton
      id={id}
      ref={btnRef}
      data-testid={testId ? testId : 'button'}
      onClick={onBtnClick}
      variant={variant ?? 'primary'}
      severity={severity}
      fullWidth={fullWidth}
      disabled={disabled}
      isLoading={isLoading}
      {...rest}
    >
      {!isLoading && icon && iconPlacement === 'before' && <IconLeft>{icon}</IconLeft>}
      {children && (
        <Label
          variant={variant}
          severity={severity}
          icon={!!icon}
          iconPlacement={iconPlacement}
          isLoading={isLoading}
        >
          {children}
        </Label>
      )}
      {isLoading && <ButtonSpinner color="currentColor" />}
      {!isLoading && icon && iconPlacement === 'after' && <IconRight>{icon}</IconRight>}
    </StyledButton>
  );
};
